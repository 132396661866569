<template>
  <div class="wrapBox" :style="{ height: screenHeight + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WrapBox",
  data() {
    return {
      screenHeight: window.innerHeight, // 初始化为当前高度
    };
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateHeight);
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener("resize", this.updateHeight);
  },
  methods: {
    updateHeight() {
      this.screenHeight = window.innerHeight; // 更新高度
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapBox {
  overflow: hidden;
}
</style>
