<template>
  <div class="flex items-center justify-between header">
    <div class="left">SMART PAY</div>
    <div class="flex items-center justify-start right">
      <el-button type="info" class="customBtn loginBtn" @click="toLogin">{{
        $t("登录")
      }}</el-button>
      <el-button type="primary" class="customBtn" @click="toLogin">{{
        $t("注册")
      }}</el-button>
      <el-button type="primary" class="customBtn" @click="toLogin">{{
        $t("申请卡片")
      }}</el-button>
      <el-dropdown trigger="click" @command="handleCommand" placement="bottom">
        <div class="langBox">
          <img src="@/assets/imgs/language.png" alt="" class="language" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(lang, index) in langs"
            :key="index"
            :command="lang"
            :class="curLanguage == lang.value ? 'languageActive' : ''"
            >{{ lang.text }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "header-page",
  data() {
    return {
      langs: [
        { text: "English", value: "en" },
        { text: "中文", value: "zh" },
        { text: "日本语", value: "jp" },
      ],
      curLanguage: localStorage.getItem("accept_language"),
      currentLan: "",
    };
  },
  methods: {
    handleCommand(command) {
      localStorage.setItem("accept_language", command.value);
      this.$i18n.locale = command.value;
      this.currentLan = this.langs.find(
        (item) => item.value === command.value
      )?.text;
      window.location.reload();
    },
    toLogin() {
      window.open("https://card.insmartpay.com/");
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 1440px;
  height: 72px;
  padding: 18px 60px;
  color: #fff;
  background-color: #000000;
  .left {
    font-family: "MiSans-Bold";
    font-size: 26px;
    line-height: 35.67px;
    text-align: left;
  }
  .right {
    .loginBtn {
      color: #9b6d09;
    }
    .language {
      margin-left: 20px;
      cursor: pointer;
    }
    .active {
      background-color: transparent;
      background: linear-gradient(180deg, #846c37 0%, #5f4b1f 100%);
      /* 渐变颜色 */
      -webkit-background-clip: text;
      /* 仅剪切文本部分的背景 */
      -webkit-text-fill-color: transparent;
      /* 文字填充颜色透明 */
      // display: inline-block;
      /* 确保文本能够应用背景剪切 */
      font-family: "MiSans-Demibold";
      font-size: 14px;
      font-weight: 600;
      line-height: 18.56px;
      text-align: left;
    }
  }
}
</style>
