<template>
  <WrapBox>
    <div class="threePage" ref="qudao" v-show="qudaoShow">
      <div class="background"></div>
      <div class="flex items-center justify-start content">
        <div class="left">
          <div
            class="smallTitle demibold animate__animated animate__bounceInUp"
          >
            {{ $t("随时随地的全球消费") }}
          </div>
          <p class="regular miniTitle">
            {{ $t("从现在开始，享受非凡的优质消费体验") }}
          </p>
          <ul class="list">
            <li class="flex items-center justify-start animate__faster">
              <img src="@/assets/imgs/bg3-2.png" alt="" />
              <p class="regular">
                {{
                  $t(
                    "支持全球范围的无限支持全球商户的无限制消费，任何时间任何地点制消费，走到哪里都不怕"
                  )
                }}
              </p>
            </li>
            <li class="flex items-center justify-start animate__faster">
              <img src="@/assets/imgs/bg3-2.png" alt="" />
              <p class="regular">
                {{ $t("数字货币快速转换，真金白银时时到账") }}
              </p>
            </li>
            <li class="flex items-center justify-start animate__faster">
              <img src="@/assets/imgs/bg3-2.png" alt="" />
              <p class="regular">
                {{
                  $t(
                    "真金白银时时到账是陌生的领域，也是您熟悉的家园，一卡通全球"
                  )
                }}
              </p>
            </li>
          </ul>
        </div>
        <div class="right animate__animated animate__fadeInRight">
          <img src="@/assets/imgs/bg3-1.png" alt="" />
        </div>
      </div>
    </div>
  </WrapBox>
</template>

<script>
import WrapBox from "./wrap/wrapBox.vue";
export default {
  name: "ThreePage",
  components: { WrapBox },
  data() {
    return {
      qudaoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let _this = this;
      _this.gdjz("qudao", 20, () => {
        _this.qudaoShow = true;
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
        // if (b > a + c) {
        //   this.qudaoShow = false;
        // }
        // if (b + c < a) {
        //   this.qudaoShow = false;
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.threePage {
  height: 100%;
  color: #fff;
  //   background-image: url("@/assets/imgs/bg3.png");
  .background {
    position: fixed; /* 固定在视口 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("@/assets/imgs/bg3.png");
    background-size: cover;
    background-position: center;
    animation: heartBeat 5s infinite; /* 心跳动画 */
    z-index: -1; /* 确保在其他内容后面 */
  }
  .content {
    position: relative; /* 确保内容在背景上方 */
    height: 100%;
    width: 100%;
    .left {
      //   width: 439px;
      flex: 1;
      height: 70%;
      margin: 181px 120px 202px 93px;
      .smallTitle {
        font-size: 44px;
        font-weight: 700;
        line-height: 58.34px;
        text-align: left;
        animation: bounceInUp 1s;
      }
      .miniTitle {
        margin-top: 50px;
        font-size: 22px;
        font-weight: 400;
        line-height: 29.17px;
        text-align: left;
        animation: bounceInUp 2s;
        color: #fff;
        opacity: 0.9;
      }
      .list {
        margin-top: 100px;
        li {
          margin-bottom: 29px;
          color: #fff;
          opacity: 0.8;
          &:nth-child(1) {
            animation: zoomInUp 3s;
          }
          &:nth-child(2) {
            animation: zoomInUp 4s;
          }
          &:nth-child(3) {
            animation: zoomInUp 5s;
          }
          img {
            width: 37px;
            height: 37px;
            margin-right: 24px;
          }
          p {
            font-size: 18px;
            font-weight: 500;
            line-height: 23.87px;
            text-align: left;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      margin-top: 136px;
      img {
        width: 676px;
        height: 70%;
      }
    }
  }
}
</style>
